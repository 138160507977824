export const environment = {
  production: true,
  baseApiUrl: 'https://gfdapi-dev.mplatformtech.com/v2/',
  backendDomain: 'https://gfdapi-dev.mplatformtech.com',
  frontendDomain: 'https://gfdvendor-dev.mplatformtech.com',

  // baseApiUrl: 'http://localhost:7500/v2/',
  // backendDomain: 'http://localhost:7500',
  // frontendDomain: 'http://localhost:4600/',
};
