<div class="modal-header">
  <h4 class="modal-title">
    Reject
    {{
      rejectionType === 'order_rejection_reason'
        ? 'Order'
        : 'Cancellation Request'
    }}
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="cancel()"
    #cancelModal
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="commit-modal-content">
    <div class="form-group" [formGroup]="reasonForm">
      <div class="commit-list-control" *ngIf="!isCustomReason">
        <h4>
          Reason for
          {{
            rejectionType === 'order_rejection_reason'
              ? 'Order Rejection'
              : 'Cancellation'
          }}
        </h4>
        <ng-select2
          *ngIf="reasonList.length > 0"
          class="select2-control"
          name=""
          id="reasonID11"
          [data]="reasonList"
          (valueChanged)="changeReason($event)"
          formControlName="reason"
          [placeholder]="'Select from list'"
        >
        </ng-select2>
      </div>
      <div
        *ngIf="submitted && f['reason'].invalid"
        class="cross-validation-error-message"
      >
        <div
          *ngIf="f['reason'].errors['required']"
          class="required-note text-danger"
        >
          Reason is required.
        </div>
      </div>
      <div class="field-with-action ng-star-inserted" *ngIf="isCustomReason">
        <div class="tags-control" style="width: 100%">
          <input
            type="text"
            placeholder="Enter custom modification reason"
            formControlName="customReason"
            class="form-control ng-pristine ng-valid ng-touched"
          />
        </div>
        <a
          href="javascript: void(0)"
          (click)="resetCustomReason()"
          class="btn field-remove-btn"
          ><img src="assets/images/trash-icon.svg" alt="trash"
        /></a>
      </div>
      <!-- *ngIf="!reasonForm.value.customReason && isCustomReason" -->
      <div
        *ngIf="
          f['customReason'].invalid &&
          f['customReason'].touched &&
          isCustomReason &&
          submitted
        "
        class="cross-validation-error-message"
      >
        <div class="required-note text-danger">Custom Reason is required.</div>
      </div>
    </div>
  </div>
  <div class="bottom-btnrow justify-content-start">
    <button class="btn t-primary-light text-capitalize" (click)="cancel()">
      Cancel
    </button>
    <button
      class="btn t-primary-btn text-capitalize"
      [disabled]=""
      (click)="save()"
    >
      Submit
    </button>
  </div>
</div>
