<table
  cellpadding="0"
  cellspacing="0"
  border="0"
  width="100%"
  bgcolor="#fff"
  style="background-color: #fff; padding: 25px 15px 50px 15px"
>
  <tr>
    <td align="center" valign="top" style="padding: 15px">
      <table cellpadding="0" cellspacing="0" border="0" width="1000">
        <tr>
          <td
            align="left"
            valign="top"
            style="
              padding: 0 0 10px 0;
              border-bottom: 1px solid rgba(181, 181, 181, 0.1);
            "
          >
            <table cellpadding="0" cellspacing="0" border="0" width="100%">
              <tr>
                <td align="left" valign="top" style="padding: 0" width="75%">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    width="100%"
                  >
                    <tr>
                      <td align="left" valign="top">
                        <img
                          src="assets/images/gfd-logo-new.svg"
                          alt="gfd-logo"
                          style="width: 125px"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 0 5px 0;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 24px;
                          font-weight: 400;
                        "
                      >
                        <address style="margin: 0px">
                          {{
                            shipmentDetails?.seller_data?.address_line1
                              ? shipmentDetails?.seller_data?.address_line1
                              : ''
                          }}
                          {{
                            shipmentDetails?.seller_data?.area
                              ? ',' + shipmentDetails?.seller_data?.area
                              : ''
                          }}{{
                            shipmentDetails?.seller_data?.city
                              ? ', ' + shipmentDetails?.seller_data?.city
                              : ''
                          }}<br />
                          {{
                            shipmentDetails?.seller_data?.country
                              ? ',' + shipmentDetails?.seller_data?.country
                              : ''
                          }}
                        </address>
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        shipmentDetails?.seller_data?.contact_no
                          ?.internationalNumber
                      "
                    >
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 0 5px 0;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 24px;
                          font-weight: 400;
                        "
                      >
                        Telephone:
                        <span style="color: #abafb3">{{
                          shipmentDetails?.seller_data?.contact_no
                            ?.internationalNumber
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td align="right" valign="top" style="padding: 0" width="25%">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    width="100%"
                  >
                    <tr *ngIf="shipmentDetails?.invoice_number">
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 0 0 0 0;
                          color: #7367f0;
                          font-family: Arial, sans-serif;
                          font-size: 23px;
                          line-height: 54px;
                          font-weight: bold;
                        "
                      >
                        Invoice # {{ shipmentDetails?.invoice_number }}<br />
                        <span *ngIf="shipmentDetails?.shipment_number">
                          Shipment #
                          {{ shipmentDetails?.shipment_number }}</span
                        >
                      </td>
                    </tr>
                    <!-- <tr *ngIf="shipmentDetails?.shipment_number">
                                            <td align="left" valign="top"
                                                style="padding: 0 0 30px 0;color: #7367f0; font-family: Arial, sans-serif; font-size: 23px; line-height: 54px; font-weight: bold;">
                                                Shipment # {{shipmentDetails?.shipment_number}}
                                            </td>
                                        </tr> -->
                    <tr>
                      <td align="left" valign="top">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          border="0"
                          width="100%"
                          style="margin: 20px 0 0 0"
                        >
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 10px 10px 0;
                                color: #8e8e8e;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              Date Issued
                            </td>
                            <td
                              align="right"
                              valign="top"
                              style="
                                padding: 0 0 10px 0;
                                color: #000;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              {{
                                shipmentDetails?.ship_date
                                  | date : 'MMM dd, YYYY'
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td align="left" valign="top" style="padding: 40px 0 0 0">
            <table cellpadding="0" cellspacing="0" border="0" width="100%">
              <tr>
                <td align="left" valign="top" style="padding: 0">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 0 0 15px 0;
                          color: #7367f0;
                          font-family: Arial, sans-serif;
                          font-size: 18px;
                          line-height: 20px;
                          font-weight: bold;
                        "
                      >
                        Invoice To:
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 0 0 15px 0;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 16px;
                          line-height: 20px;
                          font-weight: bold;
                        "
                      >
                        {{
                          shippingAddress?.full_name
                            ? shippingAddress?.full_name
                            : shippingAddress?.first_name +
                              '-' +
                              shippingAddress?.last_name
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 20px;
                          font-weight: 400;
                        "
                      >
                        {{ shippingAddress?.appartment_details }},
                        {{
                          shippingAddress?.street_name
                            ? shippingAddress?.street_name
                            : ''
                        }}
                        {{
                          shippingAddress?.area
                            ? ',' + shippingAddress?.area
                            : ''
                        }}{{
                          shippingAddress?.City?.city_name
                            ? '-' + shippingAddress?.City?.city_name
                            : ''
                        }}
                        {{
                          shippingAddress?.Country?.country_name
                            ? ',' + shippingAddress?.Country?.country_name
                            : ''
                        }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td align="left" valign="top" style="padding: 0">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 0 0 15px 0;
                          color: #7367f0;
                          font-family: Arial, sans-serif;
                          font-size: 18px;
                          line-height: 20px;
                          font-weight: bold;
                        "
                      >
                        Bill To:
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" style="padding: 0">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          border="0"
                          width="100%"
                          style="margin: 30px 0 0 0"
                        >
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 10px 10px 0;
                                color: #8e8e8e;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              Payment Type
                            </td>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 0 10px 0;
                                color: #000;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              {{ shipmentDetails?.payment_type }}
                            </td>
                          </tr>
                          <tr *ngIf="lastCardDigit">
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 10px 10px 0;
                                color: #8e8e8e;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              Card Details
                            </td>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 0 10px 0;
                                color: #000;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              {{ shipmentDetails?.card_name }} Ending with
                              {{ lastCardDigit ? lastCardDigit : '' }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 10px 10px 0;
                                color: #8e8e8e;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              Payment Method
                            </td>
                            <td
                              align="left"
                              valign="top"
                              style="
                                padding: 0 0 10px 0;
                                color: #000;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                              "
                            >
                              {{
                                shipmentDetails?.payment_method
                                  ? shipmentDetails?.payment_method
                                  : '-'
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td align="left" valign="top" style="padding: 50px 0">
            <table cellpadding="0" cellspacing="0" border="0" width="100%">
              <tr>
                <th
                  align="left"
                  valign="top"
                  style="
                    background-color: #f5f6f8;
                    padding: 15px;
                    text-transform: uppercase;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                  "
                >
                  Item
                </th>
                <th
                  align="left"
                  valign="top"
                  style="
                    background-color: #f5f6f8;
                    padding: 15px;
                    text-transform: uppercase;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                  "
                >
                  Description
                </th>
                <th
                  align="left"
                  valign="top"
                  style="
                    background-color: #f5f6f8;
                    padding: 15px;
                    text-transform: uppercase;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                  "
                >
                  Price
                </th>
                <th
                  align="left"
                  valign="top"
                  style="
                    background-color: #f5f6f8;
                    padding: 15px;
                    text-transform: uppercase;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                  "
                >
                  Qty
                </th>
                <th
                  align="left"
                  valign="top"
                  style="
                    background-color: #f5f6f8;
                    padding: 15px;
                    text-transform: uppercase;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                  "
                >
                  Total
                </th>
              </tr>
              <tr *ngFor="let item of items">
                <td
                  align="left"
                  valign="top"
                  style="
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  {{
                    item?.variants?.name
                      ? item?.product_name + ' - ' + item?.variants?.name
                      : item?.product_name
                  }}
                </td>
                <td
                  align="left"
                  valign="top"
                  style="
                    width: 250px;
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  <p [innerHtml]="item?.product_short_description"></p>
                </td>
                <td
                  align="left"
                  valign="top"
                  *ngIf="item?.variants?.price"
                  style="
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  <sub
                    style="font-size: 10px; bottom: 0; vertical-align: baseline"
                    >AED </sub
                  >{{
                    item?.variants?.sale_price &&
                    item?.variants?.sale_price !== ''
                      ? (item?.variants?.sale_price | number : '1.2-2')
                      : (item?.variants?.price | number : '1.2-2')
                  }}
                </td>
                <td
                  align="left"
                  valign="top"
                  *ngIf="!item?.variants?.price"
                  style="
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  <sub
                    style="font-size: 10px; bottom: 0; vertical-align: baseline"
                    >AED </sub
                  >{{ item?.product_price | number : '1.2-2' }}
                </td>
                <td
                  align="left"
                  valign="top"
                  style="
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  {{ item?.product_quantity ? item?.product_quantity : 1 }}
                </td>
                <td
                  align="left"
                  valign="top"
                  style="
                    padding: 15px;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                  "
                >
                  <sub
                    style="font-size: 10px; bottom: 0; vertical-align: baseline"
                    >AED
                  </sub>
                  {{ item?.subtotal ? (item?.subtotal | number : '1.2-2') : 0 }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  align="right"
                  valign="top"
                  style="padding: 15px"
                >
                  <table cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <th
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          text-transform: uppercase;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: bold;
                        "
                      >
                        Subtotal:
                      </th>
                      <th
                        align="right"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          text-transform: uppercase;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: bold;
                        "
                      >
                        <sub
                          style="
                            font-size: 10px;
                            bottom: 0;
                            vertical-align: baseline;
                          "
                          >AED&nbsp;</sub
                        >{{ finalSubtotal | number : '1.2-2' }}
                      </th>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        Discount:
                      </td>
                      <td
                        align="right"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        <sub
                          style="
                            font-size: 10px;
                            bottom: 0;
                            vertical-align: baseline;
                          "
                          >AED&nbsp;</sub
                        >{{ finalDiscount | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        Tax:
                      </td>
                      <td
                        align="right"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        <sub
                          style="
                            font-size: 10px;
                            bottom: 0;
                            vertical-align: baseline;
                          "
                          >AED&nbsp;</sub
                        >{{ finalTax | number : '1.2-2' }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        Shipping Fee:
                      </td>
                      <td
                        align="right"
                        valign="top"
                        style="
                          padding: 10px 15px;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                        "
                      >
                        <sub
                          style="
                            font-size: 10px;
                            bottom: 0;
                            vertical-align: baseline;
                          "
                          >AED&nbsp;</sub
                        >0.00
                      </td>
                    </tr>
                    <tr class="total-td">
                      <th
                        align="left"
                        valign="top"
                        style="
                          background: rgba(115, 103, 240, 0.07);
                          padding: 10px 15px;
                          text-transform: uppercase;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: bold;
                        "
                      >
                        Total:
                      </th>
                      <th
                        align="right"
                        valign="top"
                        style="
                          background: rgba(115, 103, 240, 0.07);
                          padding: 10px 15px;
                          text-transform: uppercase;
                          color: #000;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: bold;
                        "
                      >
                        <sub
                          style="
                            font-size: 10px;
                            bottom: 0;
                            vertical-align: baseline;
                          "
                          >AED&nbsp; </sub
                        >{{ finalGrandTotal | number : '1.2-2' }}
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td
            align="left"
            valign="top"
            style="
              padding: 15px;
              border-top: 1px solid rgba(181, 181, 181, 0.1);
            "
          >
            <table cellpadding="0" cellspacing="0" border="0" width="100%">
              <tr>
                <td
                  align="left"
                  valign="top"
                  style="
                    padding: 0 0 15px 0;
                    color: #000;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: bold;
                  "
                >
                  Payment Terms:
                </td>
              </tr>
              <tr>
                <td
                  align="left"
                  valign="top"
                  style="
                    color: #333;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                  "
                >
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
