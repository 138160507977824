<header class="header-wrapper">
  <nav class="navigation-bar">
    <div class="navbar-container">
      <div class="left-side">
        <button class="navbar-toggler" type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          *ngIf="profileDetails.status === 1"
          class="head-search-box"
          [ngClass]="{ active: isResultBoxAppears }"
        >
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchKey"
            (ngModelChange)="changeSearchKey()"
            (focus)="onSearchTextFocusChange(true)"
            (focusout)="onSearchTextFocusChange(false)"
          />
          <button class="btn search-btn t-primary-btn">
            <img src="assets/images/search-icon-white.svg" alt="search-icon" />
          </button>
          <div class="search-result-box">
            <ul>
              <li *ngFor="let item of resultBoxOptions">
                <a href="javascript: void(0)" (click)="onOptionSelect(item)">
                  <span>{{ searchKey }}</span> {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="navgiation-block">
          <ul class="notification-list-group">
            <app-notifications
              *ngIf="profileDetails.status === 1"
            ></app-notifications>
            <li
              class="notification-item email"
              *ngIf="profileDetails.status === 1"
            >
              <a
                href="javascript: void(0)"
                (click)="gotoTicket()"
                [routerLink]="['/home/support/admin-tickets']"
              >
                <img src="assets/images/mail-icon.svg" alt="notified-icon" />
                <!-- Show the dot if there are unread tickets -->
                <span
                  *ngIf="unreadTicketsCount > 0"
                  class="notification-badge"
                ></span>
              </a>
            </li>
            <li class="user-type">
              <div class="content">
                <div class="name">{{ profileDetails?.full_name }}</div>
                <span *ngIf="role_name" class="acc-type">{{ role_name }}</span>
              </div>
              <div class="avatar-img">
                <a [routerLink]="['/home/account-settings/profile']">
                  <img
                    [src]="
                      profileDetails?.vendor_profile
                        ? profileDetails?.vendor_profile
                        : 'assets/images/profile-avatar-img.svg'
                    "
                    alt="vendor-profile"
                  />
                  <span class="notification-badge"></span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
