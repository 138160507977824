import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Idle } from '@ng-idle/core';
import * as checkPasswordStrength from 'check-password-strength';
import * as CryptoTS from 'crypto-ts';
import { ToastrService } from 'ngx-toastr';

import Swal from 'sweetalert2';

import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public isShowHeader = true;
  public isShowFooter = true;
  public generalConfigData: any = {};
  isContainSpace = true;

  constructor(
    private toastrService: ToastrService,
    private storageService: StorageService,
    private router: Router,
    private idle: Idle,
    private http: HttpClient
  ) {}

  // Encode Decode URL
  ngEncode(param: string) {
    return window.btoa(unescape(encodeURIComponent(param)));
  }

  ngDecode(param: string) {
    return decodeURIComponent(escape(window.atob(param)));
  }

  // Encrypt Decrypt password
  decryptionAES(msg: any) {
    const bytes = CryptoTS.AES.decrypt(msg, 'meydan secret key');
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }

  encryptAES = (msg: any) =>
    CryptoTS.AES.encrypt(msg, 'meydan secret key').toString();

  // Toastr
  public displayError(title: string, message: string = ''): void {
    this.toastrService.error(message, title);
  }

  public displaySuccess(title: string, message: string = '') {
    this.toastrService.success(message, title);
  }

  public displayInfo(title: string, message: string = ''): void {
    this.toastrService.info(message, title);
  }

  public displayWarning(title: string, message: string = ''): void {
    this.toastrService.warning(message, title);
  }

  public logout(): void {
    this.idle.stop();
    this.router.navigate(['/login']);
    this.storageService.clearCookie();
  }

  public validatePassword(password: any): any {
    if (password) {
      const passStrength: any =
        checkPasswordStrength.passwordStrength(password);
      const isContainSpace = !/\s/g.test(password);
      const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/g.test(password);
      const isMinLength = password.length >= 8;

      return {
        passStrength,
        isContainSpace,
        containsSpecialChar,
        isMinLength,
      };
      // if (/\s/g.test(password)) {
      //   this.isContainSpace = false;
      // } else {
      //   this.isContainSpace = true;
      // }
      // return { passStrength, isContainSpace: this.isContainSpace };
    }
    return null;
  }

  // Confirm box
  public confirmationDialog(
    title: string,
    text: string = '',
    confirmButtonText: string = 'Yes!'
  ): any {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
    });
  }

  public alertDialog(title: string, text: string = ''): any {
    return Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      // confirmButtonColor: '#3085d6',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'View Ticket',
      // confirmButtonText: 'Open!',
      cancelButtonText: 'Close',
    });
  }

  public customTicketDialog(title: string, text: string = ''): any {
    return Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      // showCancelButton: true,
      showConfirmButton: true,
      // cancelButtonText: 'Reopen ticket',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Create new ticket',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      denyButtonColor: '#6e7881',
      showDenyButton: true,
      denyButtonText: `Reopen ticket`,
    });
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  alphaOnly(event: any) {
    const key = event.keyCode;
    return (key >= 65 && key <= 90) || key == 8 || key == 32;
  }

  // times
  getDayHours(): any {
    const hours = [];
    for (let i = 0; i < 13; i++) {
      hours.push({
        id: i + '',
        text: i + '',
      });
    }
    return hours;
  }

  getDayMinutes(): any {
    const mins = [];
    for (let i = 0; i < 61; i++) {
      mins.push({
        id: i,
        text: i,
      });
    }
    return mins;
  }

  disableDate() {
    return false;
  }

  createParamsURL(payload: { [key: string]: any }): string {
    const url = Object.keys(payload)
      .map((k) => [k, payload[k]].map(encodeURIComponent).join('='))
      .join('&');
    return url;
  }

  getEditorConfig(): AngularEditorConfig {
    return {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      toolbarHiddenButtons: [['fontName']],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: false,
    };
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    const todayDate = `${year}-${month}-${day}`;
    return todayDate;
  }

  getCurrentTime() {
    const d = new Date(),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    const currentTime = h + ':' + m;
    return currentTime;
  }

  // Page option
  getPageSizeOptions() {
    return [5, 10, 25, 50, 100, 200];
  }

  getRandomId() {
    return Math.floor(Math.random() * 9000000000 + 1000000000);
  }

  fileDownaload(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public downloadFile(
    url: string,
    filename: string,
    trimmed_filename?: string
  ) {
    console.log('trim: ' + trimmed_filename);
    const fileUrl = url;
    this.fileDownaload(fileUrl).subscribe((blob: Blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Use trimmed_filename if provided, otherwise use filename
      link.download = trimmed_filename || filename;
      // link.download = filename;
      link.click();
    });
  }
}
