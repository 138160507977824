import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VendorService } from 'src/app/core/services/vendor.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('notificationButton') notificationButton!: ElementRef;
  @ViewChild('notificationDiv') notificationDiv!: ElementRef;

  isNotificationModalOpen = false;
  orderNotificationsLists: any[] = [];
  invoiceNotifications: any[] = [];
  invoiceNotificationsTodos: any[] = [];
  isNewNotificationGet = false;
  currentTab = 'all';
  vendorID = this.storageService.getCookie('userID');
  totalInvCount: any = 0;
  totalNotiCount: any = 0;
  unreadTicketsCount: any = 0;

  constructor(
    private vendorService: VendorService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private generalService: GeneralService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.socketInit();
    this.getNewNotification();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.notificationButton?.nativeElement &&
        e.target !== this.notificationDiv?.nativeElement &&
        !this.notificationDiv.nativeElement.contains(e.target) &&
        !this.notificationButton.nativeElement.contains(e.target)
      ) {
        this.isNotificationModalOpen = false;
        document
          .getElementsByTagName('nav')[0]
          ?.classList?.remove('body-layer');
      }
    });
  }

  socketInit(): void {
    if (this.vendorID) {
      this.vendorService.socketConnection(this.vendorID);
    }
  }

  getInvoiceNotifications(): void {
    this.vendorService.getInvoiceNotifications(this.vendorID).subscribe(
      (res) => {
        this.invoiceNotifications = res?.data?.done;
        this.invoiceNotificationsTodos = res?.data?.todo;
        this.totalInvCount = res?.total_records;
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  getNotifications(): void {
    this.vendorService.getHeaderNotifications(this.vendorID).subscribe(
      (res) => {
        this.orderNotificationsLists = res.data;
        this.totalNotiCount = res?.total_records;
        this.unreadTicketsCount = res?.unread_tickets_count;

        // Update the unread count in the shared service
        this.vendorService.setUnreadCount(this.unreadTicketsCount);
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  getNewNotification(): void {
    this.getInvoiceNotifications();
    this.getNotifications();
    this.vendorService
      .listenEvent('vendorInvoiceNotification')
      .subscribe((data: any) => {
        if (data && data?._id) {
          this.getInvoiceNotifications();
          this.isNewNotificationGet = this.isNotificationModalOpen
            ? false
            : true;
        }
      });
    this.vendorService
      .listenEvent('vendorOrderNotification')
      .subscribe((data: any) => {
        if (data && data?._id) {
          this.getNotifications();
          this.isNewNotificationGet = this.isNotificationModalOpen
            ? false
            : true;
        }
      });
  }

  openModal(): void {
    this.isNewNotificationGet = false;
    this.isNotificationModalOpen = !this.isNotificationModalOpen;
    if (this.isNotificationModalOpen) {
      document.getElementsByTagName('nav')[0]?.classList?.add('body-layer');
    } else {
      document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
    }
  }

  sendInvoice(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  ngOnDestroy(): void {
    this.vendorService.socketDisconnect();
    this.isNewNotificationGet = false;
  }

  closeNotifyModal(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  markAsReadNotification(id: any): void {
    const payload = {
      notification_id: id,
      is_all: false,
    };
    this.vendorService.markAsReadNotification(payload).subscribe(
      (res) => {
        this.getInvoiceNotifications();
        this.getNotifications();
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  redirectToPage(page: string, id: string): void {
    this.closeNotifyModal();

    switch (page) {
      case 'admin_support':
        this.router.navigate(['/home/support/admin-tickets']);
        break;
      case 'admin_user_mgt':
        this.router.navigate(['/home/user-management/admin']);
        break;
      case 'vendor_user_mgt':
        this.router.navigate([`/home/user-management/view-vendor/${id}`]);
        break;
      case 'vendor_support':
        this.router.navigate(['/home/support/vendor-tickets']);
        break;
      case 'customer_support':
        this.router.navigate(['/home/support/customer-tickets']);
        break;
      case 'reviews':
        this.router.navigate(['/home/review/review-details'], {
          queryParams: { rid: id },
        });
        break;
      case 'services':
        this.router.navigate(['/home/service/add-service'], {
          queryParams: { sid: id },
        });
        break;
      case 'orders':
        this.router.navigate(['/home/service/order-details'], {
          queryParams: { oid: id },
        });
        break;
      default:
        console.warn('No redirect page specified');
    }
  }
}
