import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-review-status-filter',
  templateUrl: './review-status-filter.component.html',
})
export class ReviewStatusFilterComponent implements OnInit {
  @Input() filter_id = 4;
  @Output() filterID = new EventEmitter();

  reviewStatusOptions: Array<Select2OptionData> = [
    {
      id: '4',
      text: 'View all items',
    },
    {
      id: '1',
      text: 'View all approved items',
    },
    {
      id: '0',
      text: 'View all Under reviews',
    },
    {
      id: '2',
      text: 'View all rejected items',
    },
    {
      id: '3',
      text: 'View all modifications under review items',
    }
  ];

  constructor() {}

  ngOnInit(): void {}

  changeFilter(data: any): void {
    this.filterID.emit(data);
  }
}
