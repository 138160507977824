import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take, takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

import { getErrorReason, isValidFile } from './file-upload-util';
import { VendorService } from '@app/core/services/vendor.service';

@Component({
  selector: 'app-ticket-chat-configuration-modal',
  templateUrl: './ticket-chat-configuration-modal.component.html',
  styleUrls: ['./ticket-chat-configuration-modal.component.scss'],
})
export class TicketChatConfigurationModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chatModalObject: any;
  @Output() imgUploaded = new EventEmitter();
  @ViewChild('replyDiv') replyDiv: ElementRef;
  msgList: Array<any> = [];
  vendorId = this.storageService.getCookie('userID');

  config!: AngularEditorConfig;
  payload: any = {
    to_user: '',
    from_vendor: this.vendorId,
    from_user: '',
    to_vendor: '',
    from_admin: '',
    to_admin: '',
    title: '',
    message: '',
  };
  fromChatList: any = [];
  toChatList: any = [];
  isOpen = 'open';

  ticketToSend: any;
  ticketId: string;

  replyData = new UntypedFormGroup({
    msg: new UntypedFormControl('', [Validators.required]),
  });

  selectedFileCount = 0;
  ticketFormData: FormData = new FormData();
  allowedFileExt = 'png|.jpeg|.jpg|.doc|.docx|.pdf|.mp4';
  maxFileSize = 1024 * 1024 * 5;
  ticketToUpload: any[] = [];
  ticketImageUrl: any;
  ticketeUploadInput: any;

  vendorID = this.storageService.getCookie('userID');

  constructor(
    private storageService: StorageService,
    private supportService: SupportService,
    public generalService: GeneralService,
    private vendorService: VendorService
  ) {
    super();
  }

  get f() {
    return this.replyData.controls;
  }

  ngOnInit(): void {
    this.socketInit();
    this.getNewMessage();

    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
      height: '10rem',
      toolbarHiddenButtons: [
        [
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'fontName',
          'insertUnorderedList',
          'insertOrderedList',
          'heading',
          'link',
          'unlink',
        ],
        [
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'customClasses',
          'fontSize',
          'textColor',
          'backgroundColor',
          'toggleEditorMode',
        ],
      ],
    };

    this.supportService
      .readMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            console.log('Message read successfully');
            // Extract the unread_tickets_count from the response
            const unreadCount = res?.data?.unread_tickets_count || 0;
            this.vendorService.setUnreadCount(unreadCount); // Update the unread count in the service

            // Update the readMessage status in the shared service
            this.supportService.updateReadMessageStatus(true);
          } else {
             // Handle failure scenario
             this.supportService.updateReadMessageStatus(false);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatModalObject'] && changes['chatModalObject'].currentValue) {
      this.payload.ticket_id = this.chatModalObject?._id || '';
      this.payload.to_admin = this.chatModalObject?.admin || '';
      this.isOpen = this.chatModalObject?.isOpen;
      this.getMessages();
    }
  }

  ngAfterViewInit() {
    console.log(this.replyDiv);
    if (this.replyDiv) {
      setTimeout(() => {
        this.replyDiv.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 150);
    }
  }

  ngOnDestroy(): void {
    this.vendorService.socketDisconnect();
    this.markMessagesAsRead();
    // Unsubscribe all observables
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  markMessagesAsRead(): void {
    this.supportService.readMessages(this.chatModalObject._id)
      .pipe(take(1)) // This will automatically unsubscribe after the first emission
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            console.log('Messages marked as read successfully');
            const unreadCount = res?.data?.unread_tickets_count || 0;
            this.vendorService.setUnreadCount(unreadCount); // Update the unread count
          }
        },
        error: (err) => {
          console.log('Error marking messages as read', err);
        }
      });
  }

  socketInit(): void {
    if (this.vendorID) {
      this.vendorService.socketConnection(this.vendorID);
    }
  }

  getNewMessage(): void {
    this.getMessages();
    console.log('in getNewMessage');
    this.vendorService.listenEvent('adminVendorTicketNotification').subscribe(
      (messageData: any) => {
        console.log('Socket triggered:', messageData);
        if (messageData.ticket_id === this.ticketId) {
          console.log('Fetching messages for ticket:', this.ticketId);
          this.getMessages();
        }
      },
      (error) => {
        console.error('Error listening to adminNotificationData:', error);
      }
    );
  }

  getMessages(): void {
    this.ticketId = this.chatModalObject._id;
    this.supportService
      .getMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res && res?.data) {
            this.msgList = res?.data || [];
            // this.msgList?.map((item: any) => {
            //   if (item?.file_name) {
            //     const imgArr = item?.file_name?.split('.');
            //     item.file_type = imgArr[1];
            //   }
            // });

            this.msgList = res.data.map((message: any) => {
              // Process the files
              if (message.ticket_files) {
                message.files = message.ticket_files.map((fileUrl: string) => {
                  const fileType = fileUrl.split('.').pop()?.toLowerCase();
                  const fileName = fileUrl.split('/').pop() || '';
                  return {
                    file_name: fileName,
                    file_type: fileType,
                    img_url: fileUrl,
                  };
                });
              } else {
                message.files = [];
              }
              return message;
            });

            console.log(this.msgList);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  sendMessage(): void {
    if (this.replyData.invalid) {
      this.replyData.markAllAsTouched();
      return;
    }

    const ticketId = this.chatModalObject?._id;
    const message = this.replyData.value.msg;

    // Check if message and ticketId are already present in the FormData
    const hasMessage = this.ticketFormData.has('message');
    const hasTicketId = this.ticketFormData.has('ticketId');

    if (!hasMessage || this.ticketFormData.get('message') !== message) {
      this.ticketFormData.append('message', message);
    }

    if (!hasTicketId || this.ticketFormData.get('ticketId') !== ticketId) {
      this.ticketFormData.append('ticketId', ticketId);
    }

    // call file upload if file is present
    if (this.selectedFileCount > 0) {
      // this.ticketFormData.append('message', this.replyData.value.msg);
      // this.ticketFormData.append('ticketId', ticketId);
      this.supportService
        .uploadTicketChatFile(this.ticketFormData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res?.success) {
              this.getMessages();
              this.resetForm();
            } else {
              this.generalService.displayError(res?.message);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );

      // else call just send message
    } else {
      const payload = {
        message: this.replyData.value.msg,
        ticket_id: this.chatModalObject?._id,
        from_vendor: this.storageService?.getCookie('userID'),
      };
      this.supportService
        .sendMessage(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res?.success) {
              this.getMessages();
              this.resetForm();
            } else {
              this.generalService.displayError(res?.message);
            }
            // if (this.selectedFileCount > 0) {
            //   this.uploadFiles(payload?.ticket_id);
            // }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  resetForm(): void {
    this.replyData.reset();
    this.selectedFileCount = 0;
    this.ticketFormData = new FormData();
  }

  uploadFiles(ticketId: any): void {
    if (this.ticketToUpload.length > 0) {
      this.ticketFormData.append('ticket_id', ticketId); // Attach the ticket ID to the FormData

      // Call the file upload API
      this.supportService.uploadTicketChatFile(this.ticketFormData).subscribe(
        (res) => {
          if (res?.success) {
            this.generalService.displaySuccess(res?.message);
            // this.close({ onSaveClose: true });
            this.imgUploaded.emit();
            this.getMessages();
            this.resetForm();
          } else {
            this.generalService.displayError(res?.message);
            // this.close({ onSaveClose: true });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
    }
  }

  uploadTicketFile(event: any, ticketID: any): void {
    const file: File = event.target.files[0];
    this.generalService
      .confirmationDialog('Are You Sure?', `You want to send this file`)
      .then((result: any) => {
        if (result.isConfirmed) {
          if (file) {
            this.ticketFormData = new FormData();
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToSend = event.target.files[0];
            if (this.ticketToSend) {
              this.ticketFormData.append('ticket_id', ticketID);
              this.supportService
                .uploadTicketFile(this.ticketFormData)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: (res) => {
                    if (res) {
                      this.imgUploaded.emit();
                      this.generalService.displaySuccess(res?.message);
                      this.getMessages();
                      this.resetForm();
                    }
                  },
                  error: (error) => {
                    this.generalService.displayError(error?.error?.message);
                  },
                });
            }
          } else {
            this.ticketeUploadInput.nativeElement.value = '';
            this.generalService.displayError('Please select file');
          }
        }
      });
  }

  handleFileInput(event: any): void {
    this.ticketFormData = new FormData();
    // this.ticketId = id;
    const files = event.target.files;
    this.selectedFileCount = 0;
    if (files && files.length > 0) {
      if (files.length <= 10) {
        for (const file of files) {
          const fileDetail = isValidFile(
            file,
            this.maxFileSize,
            this.allowedFileExt,
            ''
          );
          if (fileDetail.validity) {
            this.selectedFileCount = this.selectedFileCount + 1;
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToUpload.push(file);
          } else {
            const reason = getErrorReason(fileDetail.fileData);
            this.generalService.displayError(reason);
          }
        }
        this.processFiles();
      } else {
        this.generalService.displayError('Maximum 10 files are allowed');
      }
    } else {
      this.generalService.displayError('Please select files');
    }
  }

  processFiles(): void {
    this.ticketToUpload.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.ticketImageUrl = event.target.result;
        if (this.ticketeUploadInput) {
          this.ticketeUploadInput.nativeElement.value = '';
        }
      };
      reader.readAsDataURL(file);
    });
  }

  get getUploadedFileName() {
    const allFiles = this.ticketFormData.getAll('ticketfiles');
    const name = allFiles.map((item) => item['name']).join(',\n');
    return name;
  }
}
