<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <!--Enter a new number-->
    <div *ngIf="!isShowSMSOTP">
      <div class="modal-header">
        <h4 class="text-uppercase">Enter a new mobile number</h4>
        <button
          type="button"
          (click)="closeMdl()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <img src="assets/images/close-outline.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <div class="profile-enter-new-email" [formGroup]="mobileForm">
          <p class="text-center">Please enter the New Contact Number</p>
          <div class="form-group">
            <ngx-intl-tel-input
              [cssClass]="'form-control w-100'"
              [disabled]="true"
              [onlyCountries]="allowedCountries"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedArabEmirates"
              [phoneValidation]="true"
              [maxLength]="15"
              [numberFormat]="PhoneNumberFormat.International"
              [separateDialCode]="separateDialCode"
              name="phone"
              formControlName="mobile"
            >
            </ngx-intl-tel-input>
            <div
              *ngIf="f['mobile'].invalid && f['mobile'].touched"
              class="cross-validation-error-message"
            >
              <div *ngIf="f['mobile'].errors" class="form-text text-danger">
                Phone number is required and must be according to the selected
                country
              </div>
            </div>
          </div>
          <div class="bottom-btnrow mt-4">
            <button class="btn t-primary-btn" (click)="sendOTP()">
              <img src="assets/images/tickmark-white.svg" alt="send-otp" />Send
              OTP
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Verify Modal-->
    <div *ngIf="isShowSMSOTP">
      <div class="modal-header">
        <h4 class="modal-title text-uppercase">VERIFY new number</h4>
        <button
          type="button"
          (click)="closeMdl()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <img src="assets/images/close-outline.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">
          OTP has been sent to register to verify the new mobile number
        </p>
        <div class="profile-enter-new-email" [formGroup]="otpForm">
          <div class="otp-form-group mt-0 pt-4">
            <div class="otp-input-control">
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitOne"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(1)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitTwo"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(2)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitThree"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(3)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitFour"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
              />
            </div>
            <a
              href="javascript: void(0)"
              (click)="resendOTP()"
              class="resend-otp"
              >Resend OTP in {{ counter }}s</a
            >
          </div>
          <div
            *ngIf="
              (g['digitOne'].invalid && g['digitOne'].touched) ||
              (g['digitTwo'].invalid && g['digitTwo'].touched) ||
              (g['digitThree'].invalid && g['digitThree'].touched) ||
              (g['digitFour'].invalid && g['digitFour'].touched)
            "
            class="cross-validation-error-message mb-2"
          >
            <div class="required-note text-danger">
              Please enter 4 digit OTP *
            </div>
          </div>
        </div>
        <div class="bottom-btnrow">
          <button class="btn t-primary-btn" (click)="verify()">
            <img src="assets/images/tickmark-white.svg" alt="verify" /> VERIFY
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
