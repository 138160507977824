import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderManagementService {
  constructor(private httpClient: HttpClient) {}

  // Invoices
  getInvoiceList(vendorID: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/invoicelist/${vendorID}?${payload}`
    );
  }

  setInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/createInvoice`,
      payload
    );
  }

  enableDisableInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkInvoiceStatusUpdate`,
      payload
    );
  }

  deleteInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkInvoiceDelete`,
      payload
    );
  }

  viewInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/viewInvoice/${payload.invoiceID}`
    );
  }

  getInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getInvoiceDetails/${payload?.orderID}/${payload?.vendorID}`
    );
  }

  createPickup(orderId: number, payload): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/order/${orderId}/create-pickup`,
      payload
    );
  }

  getOrderList(vendorID: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/orderlist/${vendorID}?${payload}`
    );
  }

  bulkOrderStatusUpdate(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/order/bulkProductOrderStatusUpdate`,
      payload
    );
  }

  deleteOrder(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkOrderDelete`,
      payload
    );
  }

  getOrderDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/viewOrder/${payload.orderID}/${payload.vendorID}`
    );
  }

  createShipping(payload: any, id: number): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/order/${id}/shipping-packages`,
      payload
    );
  }

  generateLabels(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/order/${id}/generate-labels`,
      {}
    );
  }
  getShippingDetails(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/${id}/shipping-packages`
    );
  }

  deleteShippingDetails(id: number, packageId: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/order/${id}/shipping-packages/${packageId}`
    );
  }

  readyToPickup(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/${id}/ready-to-pickup`
    );
  }
  getServiceOrderDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/serviceOrderDetails/${payload.orderID}`
    );
  }

  updateOrderStatus(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/order/updateOrderStatus/${payload.order_id}`,
      payload
    );
  }

  bulkServiceOrderStatusUpdate(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/order/bulkServiceOrderStatusUpdate`,
      payload
    );
  }

  getOrderInvoiceList(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/orderInvoiceList/${order_id}`
    );
  }

  getClientRequirements(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/clientRequirement/${order_id}`
    );
  }

  setOrderTask(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/order/updateOrderTask/${payload.orderID}`,
      payload
    );
  }

  uploadServiceOrderFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/order/uploadOrderFile`,
      payload
    );
  }

  uploadServiceOrderTaskFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/order/uploadTaskFile `,
      payload
    );
  }

  // Shipments
  getShipmentList(vendorId: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/shipmentlist/${vendorId}?${payload}`
    );
  }

  enableDisableShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkShipmentStatusUpdate`,
      payload
    );
  }

  deleteShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkShipmentDelete`,
      payload
    );
  }

  getShipmentDetails(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/viewShipment/${id}`
    );
  }

  setTracking(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/addTrackInfo/${payload.order_id}`,
      payload
    );
  }

  setShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/createShipment`,
      payload
    );
  }

  getOrderShipmentList(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/orderShipmentList/${order_id}`
    );
  }

  viewShipmentDetails(payload: any): Observable<any> {
    // get shipment details in add-shipemnt page
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getShipmentDetails/${payload?.order_id}/${payload.seller}`
    );
  }

  bulkDeleteShipments(payload: any): Observable<any> {
    return this.httpClient.post(
      `${environment.baseApiUrl}vendor/bulkShipmentDelete`,
      payload
    );
  }

  approveRejectCancellationRequest(payload: any): Observable<any> {
    return this.httpClient.put(
      `${environment.baseApiUrl}vendor/order/updateCancellationStatus`,
      payload
    );
  }

  rejectOrder(payload: any): Observable<any> {
    return this.httpClient.put(
      `${environment.baseApiUrl}vendor/order/rejectOrder`,
      payload
    );
  }

  // Email
  sendEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/sendEmail`,
      payload
    );
  }

  getOrderActivity(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/order/orderActivityLog/${order_id}`
    );
  }

  getServiceHistory(service_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/serviceHistory/${service_id}`
    );
  }
}
